
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import './styles/palette';
@import './styles/evolve_custom_styles.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$evolve-frontend-primary: mat.define-palette($evolve-primary);
$evolve-frontend-accent: mat.define-palette($evolve-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$evolve-frontend-warn: mat.define-palette(mat.$red-palette);

// FONTS
$evolve-frontend-theme: mat.define-typography-level(
  $font-family: "Open Sans",
  $font-weight: 100,
  $font-size: 1rem,
  $line-height: 1,  
  $letter-spacing: normal,
);

$typography-config: mat.get-typography-config($evolve-frontend-theme);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$evolve-frontend-theme: mat.define-light-theme((
  color: (
    primary: $evolve-frontend-primary,
    accent: $evolve-frontend-accent,
    warn: $evolve-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($evolve-frontend-theme);


// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}
.mat-green-button {
  background-color: #2c9720;
}
.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Open Sans';
    font-weight: 300;
    outline-color: transparent;
  }
}
/* You can add global styles to this file, and also import other style files */

html, body { 
  height: 100%; 
  background-color: rgb(250, 250, 250);
}
body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif; 
  font-weight: 300;
  outline-color: transparent;
  color: #464646;
  h3, h4 {
    font-size: 12px !important;    
    font-weight: 400;
    color: #464646;
  }
  h2, h3, h4 {
    margin: 0 0 2px !important;
    font-family: "Open Sans", "Helvetica Neue", sans-serif !important; 
    font-weight: 300 !important;
    letter-spacing: normal;
  }
  .span-label {
    margin-left: 20px;
  }
}
.mat-tab-labels{
  .mat-tab-label {
    min-width: 120px;
    padding: 0 10px;

  }
}

.login-container {
  .mat-form-field-infix {
    border: 1px solid #999 !important;
  }
}
.mat-tab-label-active {
  background-color: #197de1 !important;
  color: #fff;
}
.mat-tab-label {
  background-color: #d6d9e9;
}
.dialogform-container {
  .mat-tab-label {
    border: 1px solid #0d2f6b;
    pointer-events: none;
  }
}
.mat-card {
  margin: 10px;
}

.ng2-pdf-viewer-container{
  height: auto !important;
  overflow-x: initial !important;  
}

.mat-typography {
  font:  100 13.5px/1.55 "Open Sans", sans-serif;
  height: auto;
}
.mat-card-header-text {
  width: 100%;
  margin: 0px !important;
}

.mat-progress-spinner, .no-results {
  margin: 0 auto;
  text-align: center;
  min-height: 200px;
  margin-top: 200px;
}
.form-search {
  width: auto;
  float: right;
  max-height: 38px;
  padding-top: 0px;
  .mat-form-field {
    width: 58%;
    padding-left: 20px;
    &.filter-type {
      width: 250px;
    }
 
    .mat-form-field-infix {
      padding: 0px !important;
      color: #3b3b3b !important;
    }
    .mat-form-field-underline,
    .mat-form-field-ripple,
    .mat-focused .mat-form-field-ripple {
      background-color: #3b3b3b !important;
    }
    .mat-form-field-flex {
      background-color: transparent;
    }
  }
}

body {
  .full-width {
    width: 100%;
  }
  table, mat-toolbar {
    box-shadow: none !important;
  }
  //background-color: #e4f3f2;
  height: 100%;
  width: 100%;
  position: absolute;
  .right {
    float: right;
  }
   .filter-type.disabled {
    pointer-events: none;
    opacity: 0.6;
  }  
  .mat-form-field-disabled .material-icons {
    pointer-events: none;
    opacity: 0.6;
  }

  .disabled-dropdown {
    pointer-events: none;
    opacity: 0.6;    
  }
  .mat-raised-button {
    &.disabled {
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

/******************** MEDIAQUERIES ********************/
@media  all and (min-height: 700px) {
  pdf-viewer {  
    height: 70vh !important;         
  }  
  .preview {
    pdf-viewer {  
      height: 84vh !important;         
    }  
  }
  .content-container .sidebar-container mat-card-content {
    max-height: 59vh !important;      
  }
}

@media  all and (min-height: 800px) {
  pdf-viewer {  
    height: 70vh !important;         
  }  
  .preview {
    pdf-viewer {  
      height: 85vh !important;         
    }  
  }
  
  .content-container .sidebar-container mat-card-content {
    max-height: 61vh !important;      
  }
}
@media  all and (min-height: 900px) {
  pdf-viewer {  
    height: 70vh !important;         
  }  
  .preview {
    pdf-viewer {  
      height: 85vh !important;         
    }  
  }
  
  .content-container .sidebar-container mat-card-content {
    max-height: 63vh !important;      
  }
}

@media  all and (min-height: 1000px) {
  pdf-viewer {  
    height: 76vh !important;         
  }  
  .preview {
    pdf-viewer {  
      height: 88vh !important;         
    }  
  }

  .content-container .sidebar-container mat-card-content {
    max-height: 68vh !important;      
  }
  
}
@media  all and (min-height: 1100px) {
  pdf-viewer {  
    height: 76vh !important;         
  }  
  .preview {
    pdf-viewer {  
      height: 88vh !important;         
    }  
  }

  .content-container .sidebar-container mat-card-content {
    max-height: 71vh !important;      
  }
  
}

@media  all and (min-height:1200px) {
  pdf-viewer {  
    height: 74vh !important;         
  }  
  .preview {
    pdf-viewer {  
      height: 90vh !important;         
    }  
  }

  .content-container .sidebar-container mat-card-content {
    max-height: 72vh !important;      
  }
  
}

@media  all and (min-height:1250px) {
  pdf-viewer {  
    height: 74vh !important;         
  }  
  .preview {
    pdf-viewer {  
      height: 90vh !important;         
    }  
  }

  .content-container .sidebar-container mat-card-content {
    max-height: 73vh !important;      
  }
  
}

@media  all and (min-height:1300px) {
  pdf-viewer {  
    height: 74vh !important;         
  }  
  .preview {
    pdf-viewer {  
      height: 90vh !important;         
    }  
  }

  .content-container .sidebar-container mat-card-content {
    max-height: 74vh !important;      
  }
  
}

.main-container {
  @media  all and (max-width: 3500px) {
    .my-content-container {
      width: 79% !important;
      min-height: 87.5vh;          
      &.full-width {
        width: 99.2% !important;
      }
      .mat-mdc-tab-body-content {
        overflow: initial !important;
      }     
    }
    .my-notifications-container {
      min-height: 87.5vh;             
    }
    .sidebar-container {
      display: block !important;
    }
  }

  @media  all and (max-width: 3000px) {
    .my-content-container {
      width: 78.9% !important;
      min-height: 87.5vh;      
      &.full-width {
        width: 99.2% !important;
      }       
    }
    .my-notifications-container {
      min-height: 87.5vh;             
    }
  }

  @media  all and (max-width: 2600px) {
    .my-content-container {
      width: 78.6% !important;        
      min-height: 87.5vh;        
      &.full-width {
        width: 99.2% !important;
      }
    }
    .my-notifications-container {
      min-height: 87.5vh;              
    } 
  }

  @media  all and (max-width: 2400px) {
    .my-content-container {
      width: 78.6% !important;       
      min-height: 87.5vh;     
      &.full-width {
        width: 99.2% !important;
      } 
    }
    .my-notifications-container {
      min-height: 87.5vh;                  
    }  
  }

  @media  all and (max-width: 2200px) {
    .my-content-container {
      width: 78.3% !important;
      min-height: 87.5vh;     
      &.full-width {
        width: 99% !important;
      }     
    }
    .my-notifications-container {
      min-height: 87.5vh;                 
    }  
  }

  @media  all and (max-width: 2000px) {
    .details-container {
      .item-preview {
        display: block !important;
        .avatar_container {
          width: 320px !important;
          height: 220px !important;
        }
      }
      .preview{
        max-width: 320px !important;
        max-height: 320px !important;
      }
    }
    .my-content-container {
      width: 78.3% !important;    
      min-height: 87.5vh;     
      &.full-width {
        width: 99% !important;
      }     
    }
    .my-notifications-container {
      min-height: 87.5vh;                
    }  
  }
  @media  all and (max-width: 1900px) {
    .details-container {
      .item-preview {
        display: inline !important;
        .avatar_container {
          width: 300px !important;
          height: 220px !important;
        }
      }
      .preview{
        max-width: 300px !important;
        max-height: 300px !important;
      }
    }
  }
  @media  all and (max-width: 1800px) {
    .details-container {
      .item-preview {
        display: inline !important;
        .avatar_container {
          width: 280px !important;
          height: 220px !important;
        }
      }
      .preview{
        max-width: 280px !important;
        max-height: 280px !important;
      }
    }
  }
  @media  all and (max-width: 1700px) {
    .details-container {
      .item-preview {
        display: block !important;
      .avatar_container {
        width: 260px !important;
        height: 220px !important;
      }      
    }
      .preview{
        max-width: 260px !important;
        max-height: 260px !important;
      }
    }
    .my-content-container {
      width: 77.8% !important;          
      min-height: 87.5vh;   
      &.full-width {
        width: 98.5% !important;
      }
    }
    .my-notifications-container {
      min-height: 87.5vh;             
    }      
  }
  @media  all and (max-width: 1650px) {
    .details-container {
      .item-preview {
        display: inline !important;
        .avatar_container {
          width: 250px !important;
          height: 220px !important;
        }
      }
      .preview{
        max-width: 250px !important;
        max-height: 250px !important;
      }
    }
  }
  @media  all and (max-width: 1600px) {
    .details-container {
      .item-preview {
        display: inline !important;
        .avatar_container {
          width: 240px !important;
          height: 200px !important;
        }
      }
      .preview{
        max-width: 240px !important;
        max-height: 240px !important;
      }
    }
  }
  @media  all and (max-width: 1500px) {
    .details-container {
      .item-preview {
        display: inline !important;
        .avatar_container {
          width: 230px !important;
        }
      }
      .preview{
        max-width: 230px !important;
        max-height: 230px !important;
      }
    }
    .my-content-container {
      width: 77.5% !important;          
      min-height: 87.8vh;    
      &.full-width {
        width: 98.5% !important;
      }
    }
    .my-notifications-container {
      min-height: 87.8vh;              
    }
  }
  @media  all and (max-width: 1400px) {
    .details-container {
      .item-preview {
        display: inline !important;
        .avatar_container {
          width: 200px !important;
        }
      }
      .preview{
        max-width: 190px !important;
        max-height: 190px !important;
      }
    }
    .my-content-container {
      width: 77.5% !important;          
      min-height: 87.8vh;    
      &.full-width {
        width: 98.5% !important;
      }
    }
    .my-notifications-container {
      min-height: 87.8vh;            
    }
  }  
  @media  all and (max-width: 1350px) {
    .my-content-container {
      width: 77.2% !important;    
      min-height: 87.8vh;      
      &.full-width {
        width: 98.5% !important;
      }    
    }
    .my-notifications-container {
      min-height: 87.8vh;             
    }
  }
  @media  all and (max-width: 1330px) {
    .my-content-container {
      width: 77.2% !important;    
      min-height: 87.8vh;     
      &.full-width {
        width: 98.5% !important;
      }       
    }
    .my-notifications-container {
      min-height: 87.8vh;                 
    }
  }
  @media  all and (max-width: 1300px) {
    .my-content-container {
      width: 77.2% !important;    
      min-height: 87.8vh;      
      &.full-width {
        width: 98.5% !important;
      }     
    }
    .my-notifications-container {
      min-height: 87.8vh;                
    }

  }
  @media  all and (max-width: 1270px) {
    .my-content-container {
      width: 77.2% !important;    
      min-height: 87.8vh;       
      &.full-width {
        width: 98.5% !important;
      }    
    }
    .my-notifications-container {
      min-height: 87.8vh;                
    }
  }
  @media  all and (max-width: 1235px) {
    .my-content-container {
      width: 77.2% !important;    
      min-height: 87.8vh;    
      &.full-width {
        width: 98.5% !important;
      }         
    }
    .my-notifications-container {
      min-height: 87.8vh;                
    }
  }
  @media  all and (max-width: 1200px) {
    .my-content-container {
      width: 77.2% !important;    
      min-height: 87.8vh;    
      &.full-width {
        width: 98.5% !important;
      }         
    }
    .my-notifications-container {
      min-height: 87.8vh;                 
    }
  }
  @media  all and (max-width: 1170px) {
    .my-content-container {
      width: 77.2% !important;    
      min-height: 87.8vh;   
      &.full-width {
        width: 98.5% !important;
      }        
    }
    .my-notifications-container {
      min-height: 87.8vh;                 
    }
    .sidebar-container {
      display: flex !important;
    }
  }

  @media  all and (max-width: 1140px) {
    .my-content-container {
      width: 77.2% !important;    
      min-height: 87.8vh;    
      &.full-width {
        width: 98.5% !important;
      }         
    }
    .my-notifications-container {
      min-height: 87.8vh;                
    }
  }
  @media  all and (max-width: 1100px) {
    .my-content-container {
      width: 77% !important;    
      min-height: 87.8vh;      
      &.full-width {
        width: 98.5% !important;
      }       
    }
    .my-notifications-container {
      min-height: 87.8vh;                
    }
  }
  @media  all and (max-width: 1070px) {
    .my-content-container {
      width: 77% !important;    
      min-height: 87.8vh;   
      &.full-width {
        width: 98.5% !important;
      }         
    }
    .my-notifications-container {
      min-height: 87.8vh;                
    }
  }
  @media  all and (max-width: 1055px) {
    .my-content-container {
      width: 77% !important;    
      min-height: 87.8vh;   
      &.full-width {
        width: 98.5% !important;
      }         
    }
    .my-notifications-container {
      min-height: 87.8vh;                 
    }
  }
  @media  all and (max-width: 1030px) {
    .my-content-container {
      width: 77% !important;    
      min-height: 87.8vh;  
      &.full-width {
        width: 98.5% !important;
      }         
    }
    .my-notifications-container {
      min-height: 87.8vh;                
    }
  }
  @media  all and (max-width: 1000px) {
    .my-content-container {
      width: 77% !important;    
      min-height: 87.8vh;  
      &.full-width {
        width: 98.5% !important;
      }          
    }
    .my-notifications-container {
      min-height: 87.8vh;     
      width: 19% !important;         
    }
  }
/*
  @media  all and (max-height: 1650px) {
    .my-content-container {
      height: 88vh;     
    }
  }

  @media  all and (max-height: 1400px) {
    .my-content-container {
      height: 87.8vh;     
    }
  }

  @media  all and (max-height: 1200px) {
    .my-content-container {
      height: 85vh;     
    }
  }
  @media  all and (max-height: 1000px) {
    .my-content-container {
      height: 83vh;     
    }
  }

  @media  all and (max-height: 900px) {
    .my-content-container {
      height: 81vh;     
    }
  }

  @media  all and (max-height: 800px) {
    .my-content-container {
      height: 80vh;     
    }
  }

  @media  all and (max-height: 700px) {
    .my-content-container {
      height: 77vh;     
    }
  }*/  
}
