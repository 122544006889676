

body {
    mat-form-field.invalid {
        .mdc-line-ripple::after, .mdc-line-ripple::before {
            border-bottom-color:transparent !important;
        }
        .mdc-text-field {
            border: 1px solid #ed473b  !important;
        }
        input {
            color: #6c2621 !important;
        }
    }
    .active-row {
        background-color: #dddddd;
    }
    .disabled {        
        opacity: 0.6;
    
        pointer-events: none;
        &.mdc-button {
            background-color: #b3afaf !important;
        }
        div:has(.select-arrow) {
            pointer-events: none;
        }
    }

.required_item {
    color: red;
}
    .hidden {
        display: none !important;
    }
    .alignment-center {
        text-align: center !important;
    }

    background-color: #f5f5f5;
    mat-icon {
      margin: 0 !important;
    }
    color: #464646;
    // PREDEFINED STYLES
    h1 {
      font-size: 25px;
    }
    .form-sites-workareas {
        padding-top: 20px;
        mat-form-field {   
            padding-left: 25px;
            font-size: 13.5px !important;
            background-color: #003e51;

           .mat-mdc-form-field-infix {
                margin: 0px !important;
                padding: 0px !important;
                min-height: 26px !important;
            } 
        }
        .mat-mdc-text-field-wrapper {
            border: 1px solid #eaf4e9;
        }
        mat-select .mat-mdc-select-placeholder,
        .mat-mdc-select-value-text,
        .mat-mdc-select-arrow { 
            color: #eaf4e9 !important;     
            font:  13.5px/1.55 "Open Sans", sans-serif;
            font-size: 13.5px;
            font-weight: 400;   
        }
    }
    mat-card {
        &.results-card, &.services-card {
            padding:0 10px 0 10px;
            .mat-mdc-card-header {
                padding: 0px;
            }
            mat-card-content {
                //min-height:80vh;
                //padding: 0 10px 0 10px !important;       
                padding: 10px 0 10px 0 !important;
            }
        }
        mat-card-content {
            //min-height:80vh;
            //padding: 0 10px 0 10px !important;       
            padding: 25px 0 10px 0 !important;
        }
            &.mat-mdc-card {
                background-color: #fafafa;
                .my-notifications-container, .my-content-container {
                    mat-card mat-card-content {
                        padding: 10px !important;
                        background-color: #ffffff !important;
                    }
                }
            }
            
            table .user_menu, table .mat-menu-item, table .mat-mdc-menu-content {
                background-color: #197de1 !important;
                color: #e6e9fa !important;    
            }
    }
    .menu .mat-mdc-menu-content.mdc-list {
        background-color: #197de1;
        .mdc-list-item , .mdc-list-item span {
            color: #e6e9fa ;    
            font-size: 14px;
            .mat-icon {
                color: #e6e9fa ;    
                font-size: 20px;
            }
        }
    }
    
    .main-menu .mat-mdc-menu-content.mdc-list,
    .menu-dropdown-styles.mat-mdc-select-panel,
    .menu-dropdown-styles.mat-mdc-menu-panel {
        background-color: #003e51 ;
        button, mat-option {
            min-height: 22px;
            &:hover {
                opacity: 0.8;
            }
        }
        .mdc-list-item mat-icon, .mdc-list-item span, button span {
            color: #e6e9fa;    
            font-size: 20px;
        }
        .mdc-list-item span, button span  {
            font:  "Open Sans", sans-serif !important;
            font-size: 13.5px !important;
            font-weight: 400 !important; 
        }
    }

    .logged-user {
        font-size: 13.5px;
        font-weight: 400;
        font:  13.5px/1.55 "Open Sans", sans-serif;
        position: relative;
    }
    .header-text {
      color: #ecf2f8;
      letter-spacing: 0.5px;
      font-weight: 400;
    }

    .my-content-container, .edit-asset-item-container, .dialogform-container {
        &.personnel{
            .mat-chip-grid .mdc-evolution-chip-set__chips {
                display: grid !important;     
            }
            .mat-mdc-chip {
                width: 100% !important;
            }
        }
        .results-toolbar mat-form-field { 
            height: 35px !important;
            margin-right: 10px;
            .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
                top: 18px;
            }
            .mat-mdc-form-field-infix {              
              padding: 5px !important;
              min-height: 35px !important;
            }   
        }
        table {
            tbody tr:hover {
                background-color: #e8e8e8 !important;

            }
            tbody tr:nth-child(even) {
                background-color: #eeeeee;
            }
            .cdk-header-row {
                background-color: #efefef !important;
            }
            td,th {
                /* row padding */
                padding: 16px 0;
                /* row border */
                border-left: 1px solid #ccc;
                border-bottom: 1px solid #ccc !important;    
                word-break: break-word !important;
                white-space: pre-wrap !important;
                .mat-badge {
                    pointer-events: none;
                }
                span {
                    word-break: break-word !important;
                    white-space: pre-wrap !important;
                }
            }
 /*
            td:first-child, th:first-child {
                row border        
            }       */          
            td:last-child, th:last-child {
                /* row border */
                border-right: 1px solid #ccc;
            }
            tr > td:last-of-type {
            text-align: center;
            }
            td,th {
                line-height: 31px;
                font-size: 11.4px;
                padding: 0px;
                color: #46464698 !important;
                font: 300 11.5px/1.55 "Open Sans", sans-serif;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100px; 
                .icon-preview {
                    position: absolute;
                    margin-top: -13px;
                }
                button.custom {
                    height: 26px !important;
                    width: 26px !important;
                    padding: 0 !important; 
                    min-width: 24px !important;
                }          
            }
            th {
                border-top: 1px solid #ccc !important;
                font-weight: 600 !important;
                color: #464646 !important;
            }
            tr.mat-mdc-row, th.mat-mdc-row, .mdc-data-table__header-row  {
                background-color: #f8f8f8;
                height: 32px;
                
            }
        }
    }
}  


//*****FORMS
//disabling formfields colored underlines
mat-form-field {
    &.comments .mdc-text-field--filled,.mat-mdc-form-field-focus-overlay {
        background-color: transparent !important;
        .mdc-line-ripple::before, .mdc-line-ripple::after {
            border-color: transparent !important;
        }
    }
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover,    
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
    .mdc-text-field--filled .mdc-line-ripple::before,.mdc-text-field--filled .mdc-line-ripple::after{
        border-bottom-color: rgb(0 0 0 / 42%);
    }
    .mdc-line-ripple:hover::before,.mdc-line-ripple:hover::after {
        border-bottom-color: rgb(0 0 0 / 42%) !important;
    }
    .mdc-text-field:hover, .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
        border-bottom-color: rgb(0 0 0 / 42%) !important;    
    }
    &.searchbar {
        .mdc-notched-outline__notch {
            //border: 0px !important;
            border-color: transparent !important;
            position: absolute;
            padding-left: 10px;
            
        }
    }
}
.edit-asset-custom-width {
    width: 90%;
    mat-form-field { 
      width: 100% !important;
    }
  }
.form-search {
    mat-form-field {   
        color: #474747 !important;
        font-size: 13.5px !important;
        margin-top: 0px !important;  
      &.searchbar {
        min-width: 360px;
      }   
      .mat-mdc-floating-label {
        top: 16px !important;
        padding-right: 13px;        
        background: transparent;
    }
      .mat-mdc-form-field-infix {
        margin: 0px !important;
        padding: 0px !important;
        min-height: 38px !important;
            input {
                margin-top: 6px;
            }
        } 
        .mat-mdc-text-field-wrapper {
            background-color: #fffbfb !important;
        }
        mat-select {
            margin-top: 8px;
        }
    }
  }

  .services-toolbar {
    display: flex;
    font-size: 12px;
    mat-form-field {
      position: absolute;
      left: 16px;
      width: 400px;
      .mat-mdc-form-field-infix {
        padding: 5px 0 !important;
        min-height: 30px !important;
        height: 30px !important;
      }
    }
    mat-icon {
      font-size: 18px;
      float: left;
    }
  }

//*****BUTTONS
.green {
    background-color: #227719 !important;
    mat-icon {
        color: #eaf4e9;
    }
  }
  // disabling box shadow
  .mat-mdc-raised-button, .mat-mdc-raised-button:hover {
    box-shadow: none !important;
  }
  .custom-button {
    height: 32px !important;
    width: 32px !important;
    padding: 0 !important; 
    min-width: 32px !important;
    &.right {
        margin-left: 10px;
    }    
} 
.mdc-button span {
    font-family: "Open Sans" !important;
    font-size: 13px !important;
}

//*****TABS

mat-tab-group {
    &.custom div.mdc-tab.mat-mdc-tab{        
        max-width: 150px;
    }
    &.transparent  mat-tab-header {
        background-color: transparent !important;        
      }
    mat-tab-header {
        background-color: #fafafa !important;
      }
    .mat-mdc-tab.mdc-tab {
        height: 32px !important;

    }
    .mdc-tab-indicator__content--underline {
        border: transparent !important;
    }
    box-shadow: none !important;
    .mat-mdc-tab-label-container {
        border-bottom: 1px solid #ccc;
    }
    &.transparent .mat-mdc-tab-label-container {
        border-bottom: transparent; 
      }
    &.transparent  div.mdc-tab.mat-mdc-tab {
        border-left: 1px solid #cccccc;
        border-top: 1px solid #cccccc;
    }
    div.mdc-tab.mat-mdc-tab {
        max-width: 120px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;    
        border-right: 1px solid #cccccc;   
        background-color: #fafafa;

        .mdc-tab__content {
            font: 300 13px "Open Sans", sans-serif;
            letter-spacing: normal;
        }
        &.mdc-tab--active {
            background-color: #ffffff;
        }
    }
}
.my-tabs-container {
    div.mdc-tab.mat-mdc-tab {
        max-width: inherit;
        width: auto;
    }
    &.custom div.mdc-tab.mat-mdc-tab {
        max-width: 100px;        
    }
}
.my-notifications-container {
        div.mdc-tab.mat-mdc-tab {
        max-width: inherit;
        width: auto;
    }
}

//*****DIALOGS
.mat-mdc-dialog-content {
    max-height: 80vh;
    display: flex;
    mat-grid-list {
        width: 33%;
        //border-right: 2px solid #ccc;
        /*&.row-3 {
            border-right: none;
            margin-left: 5%;
        }    */        
    }
        
}
mat-dialog-container.mdc-dialog {
    min-width: 300px;
}

.v-window-header {
    font: 100 13.5px/1.55 "Open Sans", sans-serif;
    outline-color: transparent;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #003e51;
    color: #ecf2f8;
    font-size: 18px;
    height: 30px;
    padding-left: 10px;
    margin-bottom: 15px;
    .v-window-closebox {
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        width: 31px;
        padding-right: 0;
        background-color: #003e51;
        border-bottom-left-radius: 0 !important;
        color: #ecf2f8;
        font-size: 24px;
        height: 30px;
        border-radius: 0 4px 0 4px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;

    }
}

.dialogform-container, create-form-details, .detailstab-container {

    mat-form-field {
        width: 100%;
        &.invalid {     
            div.mdc-text-field {
                border-bottom: 1px solid #f44333 !important;
            }        
            mat-label, input  {
                color:#f44333 !important;
            }
        }
    }
    .mat-mdc-form-field-infix {
        font: 300 13.5px/1.55 "Open Sans", sans-serif !important;
        padding-top: 20px !important;
        padding-bottom: 1px !important;
        font-size: 13px;
        label {
            font-size: 13px; 
        }
    }
    .mat-mdc-dialog-actions {
        justify-content:center;
        padding: 30px 0 30px 0;
        text-align: center;
        display: flex;
        position: relative;
        bottom: 0;
        width: 97%;
        overflow: hidden;
    }
} 

//****SNACKBARS***//

.mat-mdc-snack-bar-container {
    &.error-snackbar {
      --mdc-snackbar-container-color: #FFF3D2;
      --mat-mdc-snack-bar-button-color: #f44333;
      --mdc-snackbar-supporting-text-color: #f44333;
      text-align: center;
    }
  }
// table custom
  mat-paginator.mat-mdc-paginator {
    background-color: #fafafa !important;
    border: 1px solid #ccc;    
    border-top: none;
    //min-width: 403px;
}
.create-manual-defect {
    .mat-grid-tile-content {
        height: 100px !important;
    }
}

.create-repair {
    mat-form-field {
        .mdc-line-ripple::before,.mdc-line-ripple::after,.mdc-line-ripple:hover::after {
            border-bottom-color:transparent !important;      
        }
        .mat-mdc-form-field-flex div {
            border-color: transparent !important;                    
        }
        .mdc-text-field {
            background-color: whitesmoke;
        }
        mat-select .mat-mdc-select-arrow-wrapper { 
            display: none !important;
        }
    }
}

.cdk-overlay-pane {
    max-width: 98vw !important
}

.edit-ut-service{
    .row {
        width: 100%;
        mat-form-field {
            width: 99%;
        .mat-mdc-form-field-infix {
            width: auto !important;
            }
        }
    }
}

// custom elements

// chiplist
chiplist-elem, chiplist-services-results, .filter-type {
    .mdc-text-field--filled .mdc-floating-label--float-above {
        transform: translateY(-155%) scale(0.75);
    }
    .mat-mdc-form-field-icon-suffix mat-icon{
        position: absolute;
        right: 0;
        top: 2px;
    }
}
.edit-asset-item-container {
    mat-form-field {
        min-width: 280px;
        .mat-mdc-text-field-wrapper{
        min-height: 45px !important;
        }
    }
}

.mat-mdc-option .mdc-list-item__primary-text,
mat-form-field textarea, .mat-mdc-chip-action span,
.mat-mdc-input-element, .mat-mdc-select-value,
mat-label  {
    font-family: "Open Sans" !important;
    font-size: 13px !important;
    backface-visibility: hidden !important;
    filter: blur(.0px) !important;
    transform: perspective(1px) translateZ(0) !important;

}

// menu actionbars

.mat-mdc-menu-panel {
    border-radius: 0;
    .mat-mdc-menu-content {
        padding: 0;
    }
    .mat-mdc-menu-item {
        min-height: 28px;
    }
}

main {
    min-width: 900px;
}